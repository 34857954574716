import React from 'react';


function Main() {
    return (
        <div>
            <div
                className="lg:h-[464px] md:h-[250px] h-[130px] bg-banerBgd  bg-cover bg-center  bg-no-repeat mt-5 md:mt-0  ">

            </div>
            <div className="max-w-[1450px] mx-auto  px-5 flex flex-col justify-center items-center ">

                <a className="font-bold bg-[#26a17c] text-black py-2 px-16 rounded-md mt-10"
                   href="https://play.tether.bet/">
                    <button>Play Now</button>
                </a>
                <div className="text-start">
                    <h1 className="font-bold text-xl md:text-4xl mt-20 mb-10"> Christmas
                        Free Spins </h1>
                    <p className="md:leading-10 text-sm md:text-base md:ml-20 mb-10 ">It's the season to spin and win! Celebrate the magic of Christmas
                        with our exclusive
                        Telegram Christmas Casino Promotion. Every week in December, unwrap the gift of 25 free
                        spins on a festive slot game.
                    </p>
                    <div className="text-center my-5">
                        <span className=" text-xl md:text-4xl font-bold"> Here's how the joy unfolds </span>
                    </div>
                    <h1 className="font-bold text-xl md:text-4xl mt-14 md:mt-20 mb-10"> Weekly
                        Delights</h1>
                    <p className="md:leading-10 text-sm md:text-base md:ml-20 mb-10 ">Campaign Period: December 1st, 2023, to December 28th, 2023.
                        Every Friday: A new Christmas-themed slot game will be revealed.
                    </p>
                    <h1 className="font-bold text-xl md:text-4xl  mb-10"> How
                        to Claim</h1>
                    <p className="md:leading-10 text-sm md:text-base md:ml-20 mb-10 ">Minimum Deposit: Make a jolly deposit of 20$ or more each week.
                        Exclusive Access: This offer is exclusively for our Telegram subscribers.
                        Claim Your Spins: Look out for our Telegram message every Friday revealing the featured
                        slot.
                    </p>
                    <h1 className="font-bold text-xl md:text-4xl  mb-10"> Spin
                        with Glee</h1>
                    <p className="md:leading-10 text-sm md:text-base md:ml-20 mb-10 ">No Wagering Requirements!
                        Maximum Win: Enjoy the holiday spirit with a chance to win up to 1000$.
                    </p>
                    <h1 className="font-bold text-xl md:text-4xl  mb-10"> Terms
                        and Conditions</h1>
                    <p className="md:leading-10 text-sm md:text-base md:ml-20 mb-10 ">This Christmas Free Spins promotion is valid from December 1st, 2023
                        (00:01 CEST) to <br/>
                        December 28th, 2023 (23:59 CEST). <br/>
                        To qualify for 25 free spins, players must make a minimum deposit of 20$ or equivalent <br/>
                        in their preferred currency once per week during the campaign period. <br/>
                        No wagering requirements are attached to the free spins. <br/>
                        The maximum withdrawal limit for free spin winnings is 1,000$. <br/>
                        Any remaining balance will be forfeited. <br/>
                        A new Christmas-themed slot game will be revealed every Friday. <br/>
                        Free spins must be used within 48 hours after being credited to a player's account. Unused <br/>
                        free spins will be removed from the player's account after the expiration date. <br/>
                        The tether.bet reserves the right to cancel or reclaim any free spins or winnings derived
                        from <br/>
                        the Promotion if it is determined that a player has violated any terms and conditions or <br/>
                        engaged in fraudulent or abusive behavior. <br/>
                        The tether.bet reserves the right to modify, suspend, or cancel the Promotion, or any part <br/>
                        thereof, at its sole discretion, without prior notice. <br/>
                        Should your free spins not be credited, please reach out to our support team for assistance. <br/>
                        By participating in the Promotion, players agree to these terms and conditions and any <br/>
                        decisions made by the tether.bet, which are final and binding.
                    </p>
                    <h1 className="font-bold text-xl md:text-4xl  mb-10"> Join
                        our Telegram community and let the Christmas Free Spins fill your holiday season
                        with festive cheer! </h1>
                </div>
            </div>
        </div>
    );
}

export default Main;